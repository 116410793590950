import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.png"
import { newCategories } from "../assets/data/newCategory"

export default function Header() {
  const { categories, galleryImages } = require("../data.json")
  // const urls = categories.map(cat =>
  //   cat.products.map(product => cat.url + "/" + product.url)
  // )
  // console.log(urls)
  // const sortedCategories = [1, 2, 5, 3, 4].map(i =>
  //   categories.find(c => c.id === i)
  // )
  // console.log(sortedCategories)

  return (
    <div>
      <nav
        class="uk-navbar-container uk-box-shadow-small"
        style={{ backgroundColor: "#fff" }}
        data-uk-navbar
      >
        <div class="uk-navbar-left">
          <ul class="uk-navbar-nav">
            <li class="uk-active">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </li>
          </ul>
        </div>

        <div class="uk-navbar-right uk-visible@s">
          <ul class="uk-navbar-nav">
            <li>
              <Link to="/about/who-are-we">About Us</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
              <div
                className="uk-navbar-dropdown products-dropdown"
                uk-dropdown="pos: bottom-justify"
                style={{ left: "365px" }}
              >
                <div className="subcontainer ">
                  <div
                    className="dropdown-grid"
                    style={{ width: "981px", height: "400px" }}
                  >
                    {newCategories.map(cat => (
                      <>
                        <div
                          style={{
                            borderLeft: "1px solid #D7D7D7",
                            width:
                              cat.title === "Special Laminates"
                                ? "420px"
                                : "auto",
                          }}
                        >
                          <ul
                            className="uk-nav uk-navbar-dropdown-nav"
                            style={
                              cat.title === "Special Laminates"
                                ? {
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "flex-start",
                                    height: "200px",
                                  }
                                : {
                                    height: "200px",
                                  }
                            }
                          >
                            <li className="uk-nav-header">
                              {!cat.noUrl ? (
                                <Link to={"/products/" + cat.url}>
                                  <b style={{ color: "#222" }}>{cat.title}</b>
                                </Link>
                              ) : (
                                <b style={{ color: "#222" }}>{cat.title}</b>
                              )}
                            </li>
                            {cat.products.map(product => (
                              <li
                                style={
                                  cat.title === "Special Laminates"
                                    ? { width: "205px" }
                                    : {}
                                }
                              >
                                <Link
                                  to={
                                    "/products/" + cat.url + "/" + product.url
                                  }
                                >
                                  {product.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </li>
            {/* <li><Link to="/gallery">Gallery</Link></li> */}
            <li>
              <a href="#">Investors Section</a>
              <div className="uk-navbar-dropdown">
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li>
                    <Link to="/investor/investors">Investors</Link>
                  </li>
                  <li>
                    <Link to="/investor/code-of-conduct">Code of Conduct</Link>
                  </li>
                  <li>
                    <Link to="/investor/company-policy">Company Policy</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="#">Insights</a>
              <div className="uk-navbar-dropdown">
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li>
                    <Link to="/insights/blog">Blog</Link>
                  </li>
                  <li>
                    <Link to="/insights/news/1">News</Link>
                  </li>
                  <li>
                    <Link to="/insights/magazines">Magazines</Link>
                  </li>
                  <li>
                    <Link to="/insights/events">Events</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="#">
                Resources
                {/* <button className="uk-button uk-button-primary">
                </button> */}
              </a>
              <div className="uk-navbar-dropdown">
                <ul className="uk-nav uk-navbar-dropdown-nav">
                  <li>
                    <Link to="/downloads">Documents</Link>
                  </li>
                  <li>
                    <Link to="/catalogues">E-Catalogue</Link>
                  </li>
                  <li>
                    <a href="/brochures">Brochures</a>
                  </li>
                  <li>
                    <Link to="/videos">Videos</Link>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            {/* <li><Link to="#">Login</Link></li> */}

            {/* <li>
              <a
                href="http://14.99.245.252:8081/HRMS/login.php"
                target="_blank"
              >
                <button className="uk-button uk-button-primary">Login</button>
              </a>
            </li> */}
          </ul>
        </div>

        <div className="uk-navbar-right uk-hidden@s">
          <div
            className=" red uk-margin-small-right"
            data-uk-toggle="target: #offcanvas-usage"
          >
            <i class="fas fa-bars fa-2x"></i>
          </div>
          {/* <button className=" red" data-uk-toggle="target: #offcanvas-usage">
            <span data-uk-icon="icon: menu"></span>
            
          </button> */}
        </div>

        <div id="brochure-modal" data-uk-modal>
          <div
            className="uk-modal-dialog uk-modal-body"
            style={{ minWidth: "70vw" }}
          >
            <button class="uk-modal-close-default" type="button" data-uk-close>
              <span className="mr1">Close</span>
            </button>
            <h2 className="uk-modal-title">Brochures</h2>
            <div className="flex flex-row-ns flex-column items-start">
              <ul className="uk-list uk-list-disc">
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/elegant-series.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Elegant Series
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/handpicked-series.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Handpicked Series
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Solid-Catalogue.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Solid Colors
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Texture-Catalogue.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Textures
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Cuboid-by-Stylam-Book-2020.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Cuboid – Cubicles &amp; Lockers
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Fascia-Stylam-Exterior-Cladding.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Fascia – Exterior Cladding
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Grenex-Stylam-Booklet.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Granex – Acrylic Solid Surface
                  </a>
                </li>
              </ul>
              <ul className="mt0 uk-list uk-list-disc">
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/STYLAM-Decorative-Laminate.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Stylam Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Violam-Laminate.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Violam Laminate{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Viotouch-Decorative-Laminate.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Viotouch Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Leader-Decorative-Laminate.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Leader Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Touch-Me-AFP-Laminate.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    TouchMe Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Gloss-Pro-Laminate.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    GlossPro+ Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/AFP-GlossPro-Prelam-Board-Stylam.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    PreLam Board – GlossPro+ &amp; TouchMe
                  </a>
                </li>
              </ul>
              <ul className="mt0 uk-list uk-list-disc">
                <h4 className="mt0 ">Specialty Surfaces</h4>

                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Speciality-Range-Mirror-Magnetic-Metallic-Lam.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Mirror, Magnetic &amp; Metallic Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Speciality-Range-Compact-HPL-Panels.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Compact Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Speciality-Range-ESD-PF-Anti%20Bacterial-Chalk-Marker-Board.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    ESD, Anti Bacterial, Post Forming Lam &amp; Chalk Boards{" "}
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Speciality-Range-TouchMe-AFP-Laminate.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    TouchMe - Anti Finger Print Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Speciality-Range-GlossPro-HGL.PDF"
                    className="uk-link"
                    target="_blank"
                  >
                    GlossPro+ Laminate
                  </a>
                </li>
                <li>
                  <a
                    href="https://s3.amazonaws.com/sty-web/brochure/Speciality-Range-Digital-Laminate-Series-Stylam.pdf"
                    className="uk-link"
                    target="_blank"
                  >
                    Digital Laminate
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
      <div id="offcanvas-usage" data-uk-offcanvas>
        <div class="uk-offcanvas-bar">
          <button
            class="uk-offcanvas-close"
            type="button"
            data-uk-close
          ></button>

          <div className="uk-margin-medium-top raleway">
            <ul data-uk-accordion>
              <li class="uk-open">
                <a class="uk-accordion-title" href="#">
                  About Us
                </a>
                <div class="uk-accordion-content">
                  <ul class="uk-nav">
                    <li>
                      <Link to="/about/who-are-we">Who Are We?</Link>
                    </li>
                    <li>
                      <Link to="/about/infrastructure">Infrastructure</Link>
                    </li>
                    <li>
                      <Link to="/about/our-core-values">Our Core Values</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a class="uk-accordion-title" href="#">
                  Products
                </a>
                <div class="uk-accordion-content">
                  {newCategories.map(cat => (
                    <>
                      <div>
                        <ul className="uk-nav">
                          <li className="">
                            {!cat.noUrl ? (
                              <Link to={"/products/" + cat.url}>
                                <b style={{ color: "#fff" }}>{cat.title}</b>
                              </Link>
                            ) : (
                              <b style={{ color: "#fff" }}>{cat.title}</b>
                            )}
                          </li>
                          {cat.products.map(product => (
                            <li>
                              <Link
                                to={"/products/" + cat.url + "/" + product.url}
                              >
                                {product.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  ))}
                </div>
              </li>
              <li>
                <a class="uk-accordion-title" href="#">
                  Investors Section
                </a>
                <div class="uk-accordion-content">
                  <ul className="uk-nav">
                    <li>
                      <Link to="/investor/investors">Investors</Link>
                    </li>
                    <li>
                      <Link to="/investor/code-of-conduct">
                        Code of Conduct
                      </Link>
                    </li>
                    <li>
                      <Link to="/investor/company-policy">Company Policy</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a class="uk-accordion-title" href="#">
                  Insights
                </a>
                <div class="uk-accordion-content">
                  <ul className="uk-nav">
                    <li>
                      <Link to="/insights/blog">Blog</Link>
                    </li>
                    <li>
                      <Link to="/insights/news/1">News</Link>
                    </li>
                    <li>
                      <Link to="/insights/magazines">Magazines</Link>
                    </li>
                    <li>
                      <Link to="/insights/events">Events</Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="uk-open">
                <a class="uk-accordion-title" href="#">
                  Resources
                </a>
                <div class="uk-accordion-content">
                  <ul class="uk-nav">
                    <li>
                      <Link to="/downloads">Documents</Link>
                    </li>
                    <li>
                      <Link to="/catalogues">E-Catalogue</Link>
                    </li>
                    <li>
                      <a href="/brochures">Brochures</a>
                    </li>
                    <li>
                      <Link to="/videos">Videos</Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <ul className="uk-list">
              <li>
                <Link class="uk-accordion-title" to="/contact">
                  Contact Us
                </Link>
              </li>
              {/* <li>
                <a
                  href="http://14.99.245.252:8081/HRMS/login.php"
                  target="_blank"
                  class="uk-accordion-title"
                >
                  Login
                </a>
              </li> */}
              {/* <li>
                            <Link class="uk-accordion-title" to="#">Download</Link>
                        </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
