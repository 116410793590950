const newCategories = [
  {
    title: "Laminates",
    url: "laminates",
    image: "",
    noUrl: true,
    products: [
      {
        title: "Decorative Laminates",
        url: "decorative-laminates",
        image: "DecorativeLaminates1",
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Decorative_Laminates_cover_cb55860f5e.png",
          title: "DECORATIVE LAMINATES",
          info: [
            "Stylam Decorative Laminates are specially designed laminate sheets of classy brown Kraft papers and decorative printed papers fused together using pressure & heat. These decorative sheets are used to add stylish ﬁnishes to furniture items, cabinets, counters, worktops and other installations and also for wall panelling.",
            "Stylam decorative laminates are available in a wide range of colours in Solids and Woodgrains designs, many evergreen and new texture ﬁnishes and are in diﬀerent sizes to cater to your varying needs. In line with changing tastes of the customer, Stylam introduces new designs at regular intervals in the market.",
            "These designs, in combination with a wide range of innovative textures, make the product range very dynamic and trendy.",
            `<div className="product-desc-img-cont" style="margin: 20px 0px">
              <img src="https://stylam-upload.s3.amazonaws.com/decorative_laminates_info_4302f9f3fd.png" alt="" />
            </div>`,
            `<p class="uk-text-center"><b>Under the brand name STYLAM, we have been combining industrious eﬀorts, wide experience and innovative product customization to consistently deliver quality and satisfaction to our clients.</b></p>`,
            `<p class="uk-text-center">Stylam laminates oﬀer an extensive range of laminates in India. Whether looking for contemporary kitchen to bohemia style living den, we oﬀer laminates to meet all your needs.</p>`,
          ],
          features: [
            {
              title: "Antibacterial & Anti-Viral",
              image:
                "https://stylam-upload.s3.amazonaws.com/excellent_stain_resistance_005e9ec961.svg",
            },
            {
              title: "Wide Designs",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2413_5131a06dea.svg",
            },
            {
              title: "Eye Catching Decorative Finish",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2414_601afdf757.svg",
            },
            {
              title: "Stain & Scratch Resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Highly_Durable_4a2cce5b9d.svg",
            },
            {
              title: "Impact Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2417_46ebc7d0c1.svg",
            },
            {
              title: "Wear Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/water_resistant_d14677c145.svg",
            },
            {
              title: "Uniform Thickness & proper back sanding",
              image:
                "https://stylam-upload.s3.amazonaws.com/water_resistant_d14677c145.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2199_2x_156e7ea0c5.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2200_2x_0fe69f2796.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2201_2x_a9de4203b5.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2203_2x_4e63b39c4b.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2202_2x_639cd8ac83.png",
          ],
          sizes: [
            "THICKNESS : 0.8mm to 1.2mm",
            "SIZE : 1220 mm X 2440 mm | 1300mm x 2800 mm | 1300mm x 3050mm",
          ],
        },
      },
      {
        title: "Post Forming Laminates",
        titleColor: "black",
        url: "post-forming-laminates",
        image: "DecorativeLaminates2",
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/POST_FORMING_LAMINATES_cover_14a61f2f48.png",
          title: "POST FORMING LAMINATES",
          info: [
            "Stylam post forming laminates are made to roll in a radius over the edges of a substrate. With added ﬂexibility, malleability and excellent thermoforming properties for forward and reverse bending applications, these Laminate enhances the decorative property of the edges of any regular laminate since it gives no seams around corners and a smooth, evenly laminated surface.",
            "Post forming laminates impress with their patterns, technology, ergonomics and material used. We are constantly developing new technical and patented innovations. As a result, products are created which oﬀer the customer the highest possible ﬂexibility. These laminates also be formed into simple cylindrical concave or convex curves.",
          ],
          features: [
            {
              title: "Impact Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Impact_Resistance_e23b1c0745.svg",
            },
            {
              title: "Moisture Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Moisture_Resistant_a9fae78225.png",
            },
            {
              title: "Scratch & Stain Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: [
            "THICKNESS : 0.6mm & above",
            "SIZE : 1300x2800 mm, 1860x3660 mm and 1860x4320 mm",
          ],
        },
      },
    ],
  },
  {
    title: "Performance Laminates",
    url: "performance-laminates",
    image: "https://stylam-upload.s3.amazonaws.com/Group_1474_97d162f9e5.png",
    info:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only ﬁve centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    products: [
      {
        title: "Electrostatic Laminates",
        url: "electrostatic-laminates",
        image: "PerformanceLaminates2",
        data: {
          cover:
            "https://stylam-upload.s3.amazonaws.com/Group_1486_2x_a52eed8a4f.png",
          info:
            "Stylam Electrostatic Dissipative laminates (ESD) diﬀuse static electric charge to minimise electrostatic charge. Stylam ESD laminates have excellent electro Static Dissipative property with 1 X 108 Ω point to point resistance and meet all required speciﬁcations",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/ELECTROSTATIC_DISSIPATIVE_LAMINATES_cover_13f76512d7.png",
          title: "ELECTROSTATIC DISSIPATIVE LAMINATES",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2561_1a3577dad1.svg",
          info: [
            "Stylam Electrostatic Dissipative laminates (ESD) diﬀuse static electric charge to minimise electrostatic charge. These ESD laminates have excellent Electro Static Dissipative property with 1 X 108 Ω point to point resistance point to ground resistance of 1 X 108 Ω and meet all required speciﬁcations for electrostatic dissipative properties. ESD laminates is suitable for a wide range of applications in IT oﬃces, call centers, laboratories, electronic industries, and pharmaceutical labs.",
            "<b>Why Special Laminates?<b>",
            `<ul>
              <li>Compliance to international standard EN 61340-4-1.</li>
              <li>Industry best Point to Point Resistance of 1 X 108 Ω and point to ground resistance of 1 X 108 Ω</li> 
              <li>Absolute charge drainage and zero voltage suppression thus prevent damage</li> 
              <li>Resistance to solvents, hot solder and ﬂuxes</li> 
              <li>Available as Post formable electrostatic dissipative laminates with slightly larger bending radius</li>
            </ul>`,
          ],
          features: [
            {
              title: "Low electrical resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2421_b9e409b574.svg",
            },
            {
              title: "Absolute charge drainage & zero voltage suppression",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2422_516b56e175.svg",
            },
            {
              title:
                "Post formable ESD option is available but will require slightly larger bending radius",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2423_658d1f7919.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2424_2x_f9897f275d.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2425_2x_970fac0530.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2428_2x_72483972f0.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2428_2x_72483972f0.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2427_2x_b1b435ecc6.png",
          ],
          sizes: [
            "THICKNESS : 0.7 mm & above",
            "SIZE : 1220x2440 mm | 1300x3050 mm",
          ],
        },
      },
      {
        title: "Chemical Resistant Laminates",
        url: "chemical-resistant-laminates",
        image: "PerformanceLaminates1",
        data: {
          cover:
            "https://stylam-upload.s3.amazonaws.com/Group_1490_2x_d4cb439d64.png",
          info:
            "Plus Guard Chemical Resistant Laminates With excellent AB & AF properties, +Plus Guard Stylam panels will meet the demands in areas where chemicals & water are used more and where contamination is a genuine threat.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/CHEMICAL_RESISTANT_LAMINATES_cover_89db8b1697.png",
          title: "CHEMICAL RESISTANT LAMINATES",
          info: [
            "Stylam Plus Guard laminates can withstand environments having chemicals and harsh reagents/solutions as they provide exceptional resistance to chemicals without compromising on style Engineered. These laminates are chemical resistant, anti-soiling, anti-bacterial, anti-fungal, thus easy to clean.",
            "Stylam oﬀers superior chemical resistant Laminates that are developed for highly corrosive areas, providing an exceptional resistance performance against areas such as medical, educational facilities, and bacterial contaminated spaces. This versatile laminate is an ideal alternative to slate, stainless steel or epoxy where cost or weight is often prohibitive.",
          ],
          features: [
            {
              title: "Highly Resistant to Chemicals",
              image:
                "https://stylam-upload.s3.amazonaws.com/High_Impact_258b376eb0.svg",
            },
            {
              title: "Scratch resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Abrasion resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2440_7d5f661494.svg",
            },
            {
              title: "Low Maintenace",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Highly Durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Highly_Durable_4a2cce5b9d.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2431_2x_bc941952fb.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2432_2x_cc58ce4a27.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2433_2x_78e54c7e82.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2435_2x_1678e44afb.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2434_2x_b1506ee78e.png",
          ],
          sizes: [
            "THICKNESS : 1mm & above (as per standard grade)",
            "SIZE : 1550x3600 mm",
            "AVAILABLE COLOR : Lab White CR-0043/S2 | Lab Grey CR-0044/S2 | Lab Black CR-0042/S2",
          ],
        },
      },
      {
        title: "Fire Retardent Grade Laminates",
        url: "fire-retardent-grade-laminates",
        image: "PerformanceLaminates3",
        data: {
          cover:
            "https://stylam-upload.s3.amazonaws.com/Group_1494_2x_10c198a044.png",
          info:
            "Stylam, Fire Retardant Laminates are manufactured by impregnating papers with special phenolic resin having imported ﬁre retardant chemicals in its compositions. This special resin formula ensures high resistance to the ﬂame propagation.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2456_2x_20cd4ddc3e.png",
          title: "FIRE RETARDANT GRADE LAMINATES",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2560_18ed8ad19c.svg",
          info: [
            "Fire retardant laminates have superior resistant to ﬂame propagation and are designed to perform in most demanding environments.",
            "These laminates are engineered with imported ﬁre retardant agents along with special decorative paper impregnated with melamine resin and craft paper with phenolic resin.",
            "They are available in 6mm & above industry best Euroclass B-s1,do ﬁre classiﬁcation and have been certiﬁed by Applus Laboratory Spain.",
            "STYLAM IS THE ONLY MANUFACTUERER in INIDA to launch ﬁre retardant thin laminates as low as 0.8mm with Euroclass C-s2,do ﬁre classiﬁcation.",
            "Stylam ﬁre retardant is the preferred choice for interiors as they combine creativity with added safety feature. This product is know for its superior ﬂame resistance and high level of strength.",
            "Fire retardant laminates is specially designed for surfaces in public places such as hotels, airports, buildings,institutions and oﬃces, elevators, conﬁned areas, schools and hospitals. These are also ideal for applications in naval and military sectors.",
          ],
          features: [
            {
              title: "Eliminates Toxic Smoke",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2444_1ebf2710a6.svg",
            },
            {
              title: "Delay Temperature Rise",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2445_d6a68ae258.svg",
            },
            {
              title:
                'Very low propagation of ﬂame "Zero droplets in case of ﬁre"',
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2446_d2ff0f4611.svg",
            },
            {
              title: "Resistant to Steam",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2447_49da096143.svg",
            },
            {
              title: "Resistant to scratch",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Resistance to immersion in boiling water",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2450_8caf5dce46.svg",
            },
            {
              title: "Resistant to cracking",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2449_71d3c26815.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2451_2x_f12a3082c8.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2452_2x_257f9ee943.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2453_2x_e92c1e14a2.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2455_2x_d147bb66c2.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2454_2x_ade8906e37.png",
          ],
          sizes: [
            "THICKNESS : Compact panels (6 mm and above) | Thin sheets (0.8 mm and above)",
            "SIZE : 1220 x 2440 mm | 1300 x 2800 mm | 1300x3050 mm",
          ],
        },
      },
    ],
  },
  {
    title: "Special Laminates",
    url: "special-laminates",
    image: "SpecialCover1",
    info:
      "Stylam brings you an unconvincing range of special laminates that are manufactured with the ﬁnest in technology. The divrse possibilities of Special Laminates let you experience surfaces with premium aesthetic appeal, along with functional beneﬁt for every unique purpose.",
    products: [
      {
        title: "Synchro Laminates",
        url: "synchro-laminates-201",
        image: "SpecialLaminates9",
        data: {
          cover: "SpecialCover2",
          info:
            "Stylam Synchro laminate has feel and look of solid wood and veneer. Stylam range is designed carefully and especially for designers, architects and proud house owners to give interiors a feel that looks natural.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/synchro_laminate_cover_81666a750d.png",
          title: "SYNCHRO LAMINATES",
          info: [
            "Stylam Synchro laminate has feel and look of solid wood and veneer. Stylam range is designed carefully and especially for designers, architects and proud house owners to give interiors a feel that looks natural. These Perfectionist laminates connect with people who love perfection, symmetry & synchronization.",
            "These laminates are specially crafted with embossed surfaces that simulate the real wood grain design in a highly sensible manner. The premium press plates are able to match the textures with patterns of each individual laminates alongas great and perfect precision. These laminates are easy to clean, scratch free and stain-resistant which comes in many colours, textures, and patterns.",
          ],
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2563_2x_4ed14cfdee.png",
          features: [
            {
              title: "Stain & Scratch Resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/excellent_stain_resistance_005e9ec961.svg",
            },
            {
              title: "Impact Resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Impact_Resistance_e23b1c0745.svg",
            },

            {
              title: "Wide Designs",
              image:
                "https://stylam-upload.s3.amazonaws.com/wide_designs_59658779ac.svg",
            },
            {
              title: "Durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Durable_5b7edc739d.svg",
            },
            {
              title: "Wear Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/water_resistant_d14677c145.svg",
            },
            {
              title: "Anti-bacterial & anti-viral",
              image:
                "https://stylam-upload.s3.amazonaws.com/anti_bacterial_80ae52bf28.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: ["THICKNESS : 1 mm & above", "SIZE : 1220x2440 mm"],
        },
      },
      {
        title: "Digital Laminates",
        url: "digital-laminates",
        titleColor: "black",
        image: "SpecialLaminates2",
        data: {
          cover: "SpecialCover3",
          info:
            "You dream, We create - let your imagination go alive with Stylam Digital laminates. Digital Laminates translates your imagination, ideas, dreams into a masterpiece that you can see, feel or touch.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/digital_laminate_cover_5abf5d8af1.png",
          title: "DIGITAL LAMINATES",
          info: [
            "You dream, We create - let your imagination go alive with Stylam Digital laminates.",
            "Digital Laminates translates your imagination, ideas, dreams into a masterpiece that you can see, feel or touch. These customised digital laminates are ideal to make your interiors memorable with your favourite pictures in the form of digitally printed laminates.",
            "Compatible and highly ﬂexible, the products developed by Stylam over the years enable you to create anything you want-right down to the millimetre, and with no limits on shape or colour. From the kitchen to the bedroom, from the living room to the wardrobe room, a personalised furnishing arrangement for your entire home is within your reach.",
          ],
          features: [
            {
              title: "Easy-to-Install Directly Over Most Surfaces",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_to_install_ead884491f.svg",
            },
            {
              title: "Custom Capabilities",
              image:
                "https://stylam-upload.s3.amazonaws.com/Custom_Capabilities_27e1c23554.svg",
            },
            {
              title: "High Impact, Abrasion, & Chemical Resistant Properties",
              image:
                "https://stylam-upload.s3.amazonaws.com/High_Impact_258b376eb0.svg",
            },
            {
              title: "Broad colour options",
              image:
                "https://stylam-upload.s3.amazonaws.com/Broad_colour_options_97b7bb68c3.svg",
            },
            {
              title: "Hundreds of Stock Images Available",
              image:
                "https://stylam-upload.s3.amazonaws.com/Hundreds_of_Stock_Images_Available_4497f067b9.svg",
            },
            {
              title: "Custom print laminates",
              image:
                "https://stylam-upload.s3.amazonaws.com/Custom_print_laminates_95bcb061e3.png",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: [
            "THICKNESS : 1 mm & above",
            "SIZE : 1220x2440 mm & 1300x3050mm",
          ],
        },
      },
      {
        title: "Mirror Shield Laminate",
        url: "mirror-shield-laminate",
        image: "SpecialLaminates7",
        data: {
          cover: "SpecialCover5",
          info:
            "Stylam mirror laminates are manufactured using real aluminium, stainless stell and copper foils bonded to a backing of phenolic resin impregnated kraft paper giving a durable, semi rigid sheet for easy fabrication and handling.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/mirror_shield_cover_a1ea96b667.png",
          title: "MIRROR SHIELD LAMINATE",
          info: [
            "Stylam mirror laminates are manufactured using real aluminium, stainless stell and copper foils bonded to a backing of phenolic resin impregnated kraft paper giving a durable, semi rigid sheet for easy fabrication and handling.",
            "Mirror shield laminate maintains its integrity for long, high traﬃc activity areas such as gymnasiums, night clubs and schools. These special laminates result in strong, durable and high quality products in a range of tones. They are suited for both residential and commercial projects.",
          ],
          features: [
            {
              title: "Gleaming ﬁnish",
              image:
                "https://stylam-upload.s3.amazonaws.com/Gleaming_nish_285041f94e.svg",
            },
            {
              title: "Mirror ﬁnish",
              image:
                "https://stylam-upload.s3.amazonaws.com/Mirror_nish_d19e654b3f.svg",
            },
            {
              title: "Heat resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Heat_resistance_5fc32d1824.svg",
            },
            {
              title: "Stain resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Impact resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Impact_resistant_71b30f3515.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: ["THICKNESS : 1 mm", "SIZE : 1220x2440 mm"],
        },
      },
      {
        title: "Metallic Laminates",
        url: "metallic-laminates",
        image: "SpecialLaminates6",
        data: {
          cover: "SpecialCover7",
          info:
            "Stylam Metallic laminates are manufactured with high quality metalized imported Aluminium Foil of 0.1mm thickness. This real metal foil is used in place of decor paper, along with kraft paper impregnated with phenol formaldehyde resin.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/metallic_laminate_cover_39bff6d9fb.png",
          title: "METALLIC LAMINATE",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/metallic_laminate_description_945bf2d767.svg",
          info: [
            "Stylam Metallic laminates are manufactured with high quality metalized imported Aluminium Foil of 0.1mm thickness. This real metal foil is used in place of decor paper, along with kraft paper impregnated with phenol formaldehyde resin. These metallic laminates are recommended only for vertical applications as they have relatively lower wear resistance as compared to Standard Laminates.",
            "Stylam metallic laminates are perfect choice across all industries from hospitality to retail to residential spaces. It has a versatility unlike any other material in producing creative, comprehensive interior designs.",
          ],
          features: [
            {
              title: "Fire retardant grade",
              image:
                "https://stylam-upload.s3.amazonaws.com/Fire_retardant_grade_992e3c4212.svg",
            },
            {
              title: "lightweight and easy-to-install",
              image:
                "https://stylam-upload.s3.amazonaws.com/lightweight_and_easy_to_install_3594324a29.svg",
            },
            {
              title: "Ease of maintenance",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Crack Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Crack_Resistant_aa6d68f065.svg",
            },
            {
              title: "colour fastness under artiﬁcial light",
              image:
                "https://stylam-upload.s3.amazonaws.com/Broad_colour_options_97b7bb68c3.svg",
            },
            {
              title: "resistance to chemicals & household cleaning products",
              image:
                "https://stylam-upload.s3.amazonaws.com/High_Impact_258b376eb0.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: ["THICKNESS : 1 mm", "SIZE : 1220x2440 mm"],
        },
      },
      {
        title: "Chalk/Marker Board",
        url: "chalk-marker-board",
        titleColor: "black",
        image: "SpecialLaminates1",
        data: {
          cover: "SpecialCover10",
          info:
            "Stylam Chalkboard Laminates are made with special type of synthetic polymer which imparts durability to surface with easy cleaning of dry erase markers. Chalkboard Laminate is the perfect solution for any space or application that demands a durable and aﬀordable writing surface.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_832_582fa4b47f.png",
          title: "Chalk/Marker Board",
          info: [
            "Stylam Chalkboard Laminates are made with special type of synthetic polymer which imparts durability to surface with easy cleaning of dry erase markers. Chalkboard Laminate is the perfect solution for any space or application that demands a durable and aﬀordable writing surface.",
            "Chalk grade laminate sheets used to manufacture the Chalkboard for smooth writing at schools, colleges, oﬃces, restaurants, notice boards, etc. The laminate sheets are pressed over craft paper core panels with melamine resins to provide the smooth and durable writing panel for commercial and residential applications. Chalkboard laminate came up with extraordinary features like easy to work and smooth erasing eﬀect.",
          ],
          features: [
            {
              title: "Easy to install",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_to_install_ead884491f.svg",
            },
            {
              title: "Easy maintenance",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Wear Resistant-better Abrasion for long life",
              image:
                "https://stylam-upload.s3.amazonaws.com/water_resistant_d14677c145.svg",
            },
            {
              title: "Good machinability",
              image:
                "https://stylam-upload.s3.amazonaws.com/good_machinability_8008e048ad.svg",
            },
            {
              title: "Excellent stain resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/excellent_stain_resistance_005e9ec961.svg",
            },
            {
              title: "Cost Eﬀective",
              image:
                "https://stylam-upload.s3.amazonaws.com/cost_e_ective_edc36b3291.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: [
            "THICKNESS : 0.8 mm & 1mm",
            "SIZE : 1220x2440 mm, 1300x3050mm",
          ],
        },
      },
      {
        title: "Unicore Laminates",
        url: "unicore-laminates",
        image: "SpecialLaminates10",
        data: {
          cover: "SpecialCover4",
          info:
            "Unicore Laminates have uniqueness of combined identical colors with core layered decorative surface. In this process,decorative papers for surface layers are infused with treated melamine resins and same decorative papers are used as core layers that",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/unicore_laminate_cover_9c9fe3d10f.png",
          title: "UNICORE LAMINATE",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/unicore_laminate_description_a7c4df64ee.png",
          info: [
            "Unicore Laminates have uniqueness of combined identical colors with core layered decorative surface.",
            "In this process,decorative papers for surface layers are infused with treated melamine resins and same decorative papers are used as core layers that are infused with specially treated thermosetting melamine resin.",
            "Unicore is a world where you could feel vibrancy of colors through & through.",
            "Flaunt your desks, doors, counter-tops, drawers, display shelves, shutters and all furniture with stunning, beautiful & ﬂawless unicore surface that uniforms the look all across.",
            "Decorative Laminates are known for the great aesthetic appeal and superior performance. It adds a touch of beauty and sophistication to your interior spaces. As a fact, Unicore Laminates oﬀer similar kind of texture, colour and patterns throughout the surface and the core, Unicore range available in any solid colours, depending upon the order quantity.",
          ],
          features: [
            {
              title: "Resistant to scratch",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Resistant to stain",
              image:
                "https://stylam-upload.s3.amazonaws.com/excellent_stain_resistance_005e9ec961.svg",
            },
            {
              title: "Anti-microbial protection",
              image:
                "https://stylam-upload.s3.amazonaws.com/anti_microbial_f9d8a0d2a6.svg",
            },
            {
              title: "Resistance against surface wear",
              image:
                "https://stylam-upload.s3.amazonaws.com/Impact_resistant_71b30f3515.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: [
            "THICKNESS : 1 mm & above",
            "SIZE : 1220x2440 mm",
            "Gloss, Matt, Suede,Unicore range available in any solid colors, depending upon the order quantity",
          ],
        },
      },
      {
        title: "Magnetic Laminates",
        url: "magnetic-laminates",
        image: "SpecialLaminates4",
        data: {
          cover: "SpecialCover6",
          info:
            "Stylam Magnetic Collection is the high-tech hybrid of industrial ﬂair and sophisticated design. Magnetic Laminates have a strong magnetic eﬀect and can be written on with board nonpermanent markers ideal for a wide variety of applications in environments such as retail, education",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/magnetic_laminate_cover_1754837ef9.png",
          title: "MAGNETIC LAMINATE",
          info: [
            "Stylam Magnetic Collection is the high-tech hybrid of industrial ﬂair and sophisticated design. Magnetic Laminates have a strong magnetic eﬀect and can be written on with board nonpermanent markers ideal for a wide variety of applications in environments such as retail, education, commercial and residential. Magnetic Laminate is a special type of Laminate that can use a magnet to stick the paper or picture directly to the laminate.",
            "Magnetic Laminates are HPL (High Pressure Laminates) that contain a thin foil layer of iron sandwiched in the HPL Backer, creating a magnetic eﬀect.Stylammagnetic laminates invented a space that don’t exists and turned the blackboard into a surprising “collaborative hotspot”. A creative solution to organize your work station in front of one smart surface to write on, draw, highlight sheets, notes and ideas and then comfortable shelves to place pens, telephone and all what you don’t want on the table.",
          ],
          features: [
            {
              title: "Magnetic",
              image:
                "https://stylam-upload.s3.amazonaws.com/Magnetic_e201a8177d.svg",
            },
            {
              title: "Stain resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Hygienic",
              image:
                "https://stylam-upload.s3.amazonaws.com/Hygienic_b8a08840e6.svg",
            },
            {
              title: "Flat bonding",
              image:
                "https://stylam-upload.s3.amazonaws.com/Flat_bonding_576e9dc400.svg",
            },
            {
              title: "Ideal for communication & display",
              image:
                "https://stylam-upload.s3.amazonaws.com/Ideal_for_communication_6ea700c55a.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: [
            "THICKNESS : 1 mm & above",
            "SIZE : 1220x2440 mm,1300x3050mm",
          ],
        },
      },
      {
        title: "Flicker Laminates",
        url: "flicker-laminates",
        image: "SpecialLaminates3",
        data: {
          cover: "SpecialCover9",
          info:
            "Make your home glisten with our sparkles range of high gloss Flicker laminates. This range also oﬀers some really appealing colours and variety of aesthetically impressive options. The high gloss ﬂicker laminates allow the designer to boost the look and feel of any space virtually.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/flicker_laminate_cover_c615a6fc34.png",
          title: "FLICKER LAMINATE",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/flicker_laminate_description_295a9aaf93.png",
          info: [
            "Make your home glisten with our sparkles range of high gloss Flicker laminates. This range also oﬀers some really appealing colours and variety of aesthetically impressive options. The high gloss ﬂicker Laminates allow the designer to boost the look and feel of any space virtually.",
            "Stylam oﬀers you both silver and copper colour",
          ],
          features: [
            {
              title: "Abrasion Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Abrasion_Resistant_55ae8df1cc.svg",
            },
            {
              title: "Highly Durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Highly_Durable_4a2cce5b9d.svg",
            },
            {
              title: "Heat Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Heat_resistance_5fc32d1824.svg",
            },
            {
              title: "Impact Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Impact_resistant_71b30f3515.svg",
            },
            {
              title: "Scratch Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/product_images_1_13ea1e6e4e.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_2_d49a733cab.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_3_f2525d8445.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_4_7081be5ce6.png",
            "https://stylam-upload.s3.amazonaws.com/product_image_5_fbdd386a2d.png",
          ],
          sizes: ["THICKNESS : 1 mm", "SIZE : 1220x2440 mm"],
        },
      },
    ],
  },
  {
    title: "Luxe Laminates",
    url: "luxe-laminates",
    image: "LuxceCover1",
    info:
      "LUXCE laminates are unique laminates that adorns luxury to next level. Stylam is a pioneer in HotCoating technology from KLEIBERIT and BARBERAN, Germany and Spain. This ﬁrst ever in India state-of-the-art machine with cutting edge patented technology from germany produces ﬂawless ﬁnished quality laminates. Our 60 metres lacquer line plant uses KLEIBERIT's Hot Coating adhesives imported from Germany and special spray pump technology to apply most demanded high gloss, super matt & anti-ﬁnger print ﬁnish on laminates with high precision.",
    products: [
      {
        title: "Anti-Finger Print Laminate - Touch Me",
        url: "anti-finger-print-laminate-touch-me",
        titleColor: "black",
        image: "LuxceLaminates4",
        data: {
          cover: "LuxceCover2",
          info:
            "Anti-ﬁngerprint surface with a ultrasoft touch and deep matt ﬁnish to absorb lightfor harmonious interior spaces. Diverse patterns with a smooth feel softened by a matt surface, anti-ﬁngerprint properties for projects that make a statement.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_1748_b272d366d9.png",
          title: "TOUCH ME - ANTIFINGER PRINT LAMINATE",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Rectangle_1459_2x_6ce218e53d.png",
          info: [
            "Anti-ﬁngerprint surface Comes with an ultra soft touch and deep matt ﬁnish to absorb lightfor harmonious interior spaces. Diverse patterns with a smooth feel softened by a matt surface, anti-ﬁngerprint properties for projects that make a statement. Manufactured with state-of-Art BARBERAN line from Spain and cutting edge German KLEIBERIT hot coating technology results in thermal heating capability and anti-microbial surface protection which enhances likability of laminates.",
            "These laminates have no unpleasant smell or stains, thus adding to look ﬂawless for years ahead.",
            "Technology and composition of Touch me surfaces allow aesthetics and elegance of matt decors to remain unscattered. Anti-ﬁnger print laminates-are new and easy to maintain since HOT COATING superior technology allows micro- scratches to be easily cleaned using rubber sponge, damp cloth or iron.",
          ],
          features: [
            {
              title: "Smudge Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2457_571b5e14b9.svg",
            },
            {
              title: "super matt ﬁnish",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2458_f4d7d95c94.svg",
            },
            {
              title: "Durable & long-lasting",
              image:
                "https://stylam-upload.s3.amazonaws.com/Durable_5b7edc739d.svg",
            },
            {
              title: "scratch resistant impact resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Thermal Repair of Micro-scratches",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2449_71d3c26815.svg",
            },
            {
              title: "Competitively priced",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2465_9214f6b19d.svg",
            },
            {
              title: "Anti-bacterial anti-fungal",
              image:
                "https://stylam-upload.s3.amazonaws.com/anti_bacterial_80ae52bf28.svg",
            },
            {
              title: "environment friendly",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2463_c422c620bd.svg",
            },
            {
              title: "attractive",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2462_22f60c876f.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_1756_2x_c0e7831155.png",
            "https://stylam-upload.s3.amazonaws.com/Group_1780_2x_c31880f0d4.png",
            "https://stylam-upload.s3.amazonaws.com/Group_1768_2x_0505b39493.png",
            "https://stylam-upload.s3.amazonaws.com/Group_1784_2x_b82ddf3fb0.png",
            "https://stylam-upload.s3.amazonaws.com/Group_1772_2x_cec62c53a6.png",
          ],
          sizes: [
            "THICKNESS : 1mm & above",
            "SIZE : 1220x2440 mm | 1300x2800mm | 1300x3050mm",
          ],
        },
      },
      {
        title: "High Gloss Laminate - Gloss Pro+",
        url: "high-gloss-laminate-gloss-pro",
        image: "LuxceLaminates2",
        data: {
          cover: "LuxceCover3",
          info:
            "Scratch-proof shiny surfaces are not a ﬁction anymore. Stylam is the ﬁrst company in India to introduce revolutionary KLEIBERIT Hot Coating technology to produce the high gloss pre-laminated boards.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2481_ea58fe70b5.png",
          title: "GLOSS PRO+ HIGH GLOSS LAMINATE",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2562_2x_2668dc7ff4.png",
          info: [
            "Scratch-proof shiny surfaces are not a ﬁction anymore. Stylam is the ﬁrst company in India to introduce revolutionary HotCoating Technology by KlEIBERIT, germany to produce the high gloss laminate. We oﬀerdiverse creative patterns which anticipate market trend with styles and colours that will be combined with the most contemporary&avant-garde furnishings. The brilliance of gloss will be staying through years without any sign of wearing out!",
            "We oﬀer smart and innovative solution to residential and commerical spaces as Hot Coating technology adds luminosity on surface that will last for years.",
            "<b>HIGH GLOSS LAMINATES ARE MOISTURE/UV RESISTANT AND ALLOWS EASY CLEAN AND MAINTENANCE OF SCRATCHES, DIRT AND OTHER WEAR & TEAR</b>",
          ],
          features: [
            {
              title: "Long lasting gloss due to micro scratch resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2472_a5736e2931.svg",
            },
            {
              title: "scratch resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Impact resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Impact_resistant_71b30f3515.svg",
            },
            {
              title: "Abrasion resistant & durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2440_7d5f661494.svg",
            },
            {
              title: "Easy to maintain",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Crack resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2449_71d3c26815.svg",
            },
            {
              title: "Hygienic & safe",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2478_fe0249f593.svg",
            },
            {
              title: "Attractive",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2462_22f60c876f.svg",
            },
            {
              title: "Moisture resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Moisture_Resistant_a9fae78225.png",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2466_2x_10cf3ff509.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2467_2x_1b071ec193.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2468_2x_70439de46d.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2470_2x_ee8e5c62fb.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2469_2x_7a02808c0f.png",
          ],
          sizes: [
            "THICKNESS : 1mm & above",
            "SIZE : 1220x2440 mm & 1300x3050mm",
          ],
        },
      },
      {
        title: "Anti-Finger Print Prelam Board",
        url: "anti-finger-print-prelam-board",
        image: "LuxceLaminates1",
        data: {
          cover: "LuxceCover4",
          info:
            "Totally versatile, pre-laminated boards can be adapted to suit a variety of applications, delivering exceptional standards and properties in the world of architecture and lives.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2481_ea58fe70b5.png",
          title: "ANTI-FINGER PRINT PRELAM BOARD",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2562_2x_2668dc7ff4.png",
          info: [
            "Our Luxe range oﬀers ready to install super matt (Anti-ﬁnger print) or high gloss ﬁnished prelam boards.",
            "These pre-laminated boards can be adapted to suit a variety of applications, giving an added advantage to architects and builders.",
          ],
          features: [
            {
              title: "Anti-ﬁngerprint",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2457_571b5e14b9.svg",
            },
            {
              title: "soft-to-touch",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2484_f99e0e9a47.svg",
            },
            {
              title: "Smudge resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2485_fe1608f74c.svg",
            },
            {
              title: "super matt ﬁnish",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2458_f4d7d95c94.svg",
            },
            {
              title: "durable & long-lasting",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2487_f16073d1d5.svg",
            },
            {
              title: "anti-bacterial anti-fungal",
              image:
                "https://stylam-upload.s3.amazonaws.com/anti_bacterial_80ae52bf28.svg",
            },
            {
              title: "hygienic & safe",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2478_fe0249f593.svg",
            },
            {
              title: "Easy to maintain",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Scratch Resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2499_2x_b58321a16c.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2500_2x_12f8343649.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2501_2x_884aae048d.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2503_2x_7b9cb93242.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2503_2x_7b9cb93242.png",
          ],
          sizes: [
            "SIZE : Prelaminated Mdf Glosspro+Range (Osl, Bsl) 2440mm x 1220mm",
            "SIZE : Relaminated Mdf Anti Finger Print Range (Osl, Bsl) 2440mm x 1220mm",
          ],
        },
      },
      {
        title: "High Gloss Pre Lam Board",
        url: "high-gloss-pre-lam-board",
        image: "LuxceLaminates2",
        data: {
          cover: "LuxceCover4",
          info:
            "Totally versatile, pre-laminated boards can be adapted to suit a variety of applications, delivering exceptional standards and properties in the world of architecture and lives.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2481_ea58fe70b5.png",
          title: "HIGH GLOSS PRE LAM BOARD",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2562_2x_2668dc7ff4.png",
          info: [
            "Gloss Pro + HIgh Gloss prelam boards are suited for modern homes, commerical projects and heavily traﬃc areas with strong identity.",
            "As a result of HotCoating technology, these high gloss prelaminated boards have high abrasion resistance and durability, thus maintaining gloss level three times than ordinary gloss surface.",
          ],
          features: [
            {
              title: "long-lasting",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2492_aa79222823.svg",
            },
            {
              title: "scratch resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "moisture resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Moisture_Resistant_a9fae78225.png",
            },
            {
              title: "Abrasion resistant & durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2440_7d5f661494.svg",
            },
            {
              title: "Easy to maintain",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Hygienic & safe",
              image:
                "https://stylam-upload.s3.amazonaws.com/Moisture_Resistant_a9fae78225.png",
            },
            {
              title: "Attractive",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2462_22f60c876f.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2499_2x_b58321a16c.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2500_2x_12f8343649.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2501_2x_884aae048d.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2503_2x_7b9cb93242.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2503_2x_7b9cb93242.png",
          ],
          sizes: [
            "SIZE : Prelaminated Mdf Glosspro+Range (Osl, Bsl) 2440mm x 1220mm",
            "SIZE : Relaminated Mdf Anti Finger Print Range (Osl, Bsl) 2440mm x 1220mm",
          ],
        },
      },
    ],
  },
  {
    title: "Solid Surface",
    url: "solid-surface",
    image: "SolidSurfaceCover1",
    info:
      "Solid surface is a man-made material usually composed of a combination of alumina trihydrate (ATH), acrylic or polyester resins and pigments. Solid surface is a non-porous low-maintenance material used for surfaces such as countertops. It can mimic the appearance of granite, marble, stone, and other naturally occurring materials, and can be joined nearly invisibly by a trained craftsman. Typically manufactured in sheet form for fabrication into ﬁnished countertops, solid surface can also be cast into a variety of shapes, including sinks, shower pans and bathtubs. Sheet goods can also be heated and bent into three-dimensional shapes using a process called thermoforming, which adds to the versatility of the product. Color and design ﬂexibility are key factors when choosing engineered composites over natural stone.",
    products: [
      {
        title: "Acrylic Solid Surface - Granex",
        url: "acrylic-solid-surface-granex",
        image: "SolidSurface1",
        data: {
          cover: "SolidSurfaceCover2",
          info:
            "Granex is a high-quality composite solid surface material made of natural resins and polymers. This composition gives a unique warm quality, similar to natural stone.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/ACRYLIC_SOLID_SURFACE_GRANEX_cover_7e7ad45a61.png",
          title: "ACRYLIC SOLID SURFACE - GRANEX",
          info: [
            "Granex is a high-quality composite solid surface material made of natural resins and polymers. This composition gives a unique warm quality, similar to natural stone. Resistance to all sorts of chemical and physical inﬂuences, this impermeable surfacemakes one of the ﬁrst choices for interiors when hygiene and neat design matters the most.",
            "GRANEX -100% acrylic solid surfaceis a durable material that is composed of Alumina trihydrate, Acrylic MMA resin, and Pigments, while looks & feels like natural stone. Easy to fabricate and care for, solid-surface countertops are an excellent choice for many applications—more durable and attractive than most laminates and considerably less expensive than natural or composite stone.",
          ],
          features: [
            {
              title: "Resistant to harmful germs and cleaning agents",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2392_038619206e.svg",
            },
            {
              title: "A Broad collection of designs",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2393_77ce933661.svg",
            },
            {
              title: "Thermoformable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2394_d968ba46ec.svg",
            },
            {
              title: "Translucent",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2395_e8401d6bbe.svg",
            },
            {
              title: "Monolithic",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2396_98a4f8f884.svg",
            },
            {
              title: "Durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Durable_5b7edc739d.svg",
            },
            {
              title: "Non porous",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2400_8bb7a6612a.svg",
            },
            {
              title: "Repairable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2399_e35dc67a35.svg",
            },
            {
              title: "Eco Sustainable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2398_8500a8e9e3.svg",
            },
            {
              title: "Imperceptible Joints",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2397_8bf54401b3.svg",
            },
            {
              title: "Suitable for contact with food",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2402_7c124e467b.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2362_2x_7f434353c7.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2369_2x_518d5b918a.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2370_2x_24786bce0c.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2372_2x_d161cddeba.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2371_2x_ad691d7c27.png",
          ],
          sizes: ["THICKNESS : 6mm, 12mm", "SIZE : 760x2440 mm | 760x3660mm"],
        },
      },
      {
        title: "Modified Solid Surface - Marvella",
        url: "modified-solid-surface-marvella",
        image: "SolidSurface2",
        data: {
          cover: "SolidSurfaceCover3",
          info:
            "When it comes to decorating your spaces, your imagination travels far and wide. And to not limit it, we give you Marvella Modiﬁed Solid Surfaces, the perfect recipe for an exquisite décor. Born in India’s ﬁrst and the most advanced fully automated plant, using world-class German technology, these surfaces oﬀer unseen and spellbinding patterns along with long-lasting sturdiness.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_1394_cc577ebbe5.png",
          title: "MODIFIED SOLID SURFACE - MARVELLA",
          info: [
            "Marvella is an economical alternative to 100 % acrylic solid surface but without the property of thermoformable It is a non-porous low-maintenance material used for surfaces such as countertops, claddings and partitions. It can mimic the appearance of granite, marble, stone, and other naturally occurring materials, and can be joined invisibly by a trained craftsman.",
            "Why Marvella?",
            "A major appeal of solid surface is its seamless nature. Engineered with India’s ﬁrst and the most advanced fully automated plant, using world-class German technology, these surfaces oﬀer unseen and spellbinding patterns along with long-lasting sturdiness. Alsomakes them impervious to bacteria, staining and most of the problems inherent in natural stone.",
          ],
          features: [
            {
              title: "Resilient",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2403_0b66cca6a6.svg",
            },
            {
              title: "Resistant to chemical (nail polish remover)",
              image:
                "https://stylam-upload.s3.amazonaws.com/High_Impact_258b376eb0.svg",
            },
            {
              title: "Unique colours (non-unattainable with acrylic solid)",
              image:
                "https://stylam-upload.s3.amazonaws.com/Broad_colour_options_97b7bb68c3.svg",
            },
            {
              title: "Durable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Durable_5b7edc739d.svg",
            },
            {
              title: "Scratch resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Dent resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2411_d451d5ed59.svg",
            },
            {
              title: "Heat resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2410_9a493d35c3.svg",
            },
            {
              title: "Low maintenance",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Bacteria free",
              image:
                "https://stylam-upload.s3.amazonaws.com/anti_bacterial_80ae52bf28.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2387_2x_e4784508c8.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2388_2x_e3cf26b704.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2389_2x_2ff87a7be2.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2391_2x_cc7bf0daea.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2390_2x_a5edba5041.png",
          ],
          sizes: ["THICKNESS : 6mm, 12mm", "SIZE : 760x2440 mm | 760x3660 mm"],
        },
      },
    ],
  },
  {
    title: "Compact Laminates",
    url: "compact-laminates",
    image: "CompactCover1",
    info:
      "Compact laminates is sturdy high pressure laminate board which serves as a base for a variety of solutions like Restrooms cubicles & Lockers, internal & external wall cladding. They are made from several layers of kraft paper impregnated with thermosetting phenolic resins along with decorative paper impregnated with melamine resins on both sides. This whole set of layers is treated at high temperature and pressure to form permanent board called Compact Laminate These versatile compact laminates are available in thickness options 3mm to 25 mms. COMPACT LAMINATES WITH ANTI BACTERIAL AND ANTI_ FUNGIE PROPERTIES Retards 99.9% of fungal and bacterial growth on contact with compact laminate",
    products: [
      {
        title: "Restroom Cubicles & Lockers – Cuboid",
        url: "restroom-cubicles-&-lockers-cuboid",
        image: "CompactLaminates2",
        data: {
          cover: "CompactCover2",
          info:
            "CUBOID restroom cubicle & locker system is crafted using ﬁnest technology specially for heavy traﬃc areas, under high moisture and high humid conditions, where functionality and durability matters most.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2504_2x_af22a23fef.png",
          title: "CUBOID - REST ROOM CUBICLES & LOCKERS",
          info: [
            "Compact laminate is a durable surface that can be used for outdoor application that can withstand exposure to outdoor environment oﬀering contemporary, clean and low maintenance ﬁnish for daily use.",
            "Compact laminate is made from layers of recycled paper ﬁbres that have been dipped in resin and dried. These layers are stacked and sandwiched before being compressed under high pressure and heated to permanently meld together. Not only does this manufacturing process ensure an incredibly strong and dense structure, but it also enables the laminate to be made of varying thickness ranges.",
            `<div className="product-desc-img-cont">
              <img src="https://stylam-upload.s3.amazonaws.com/Group_2505_53ba302f4a.svg" alt="" />
            </div>`,
            `<b>RESTROOM CUBICLES</b>`,
            "Stylam presents Cuboid - a modular restroom and locker cubicle system.",
            "We oﬀer an exhaustive range that is stylish, sturdy and available in various surface designs . Cuboid Restroom cubicle systems come in special dimensions that take care of needs of physically challenged individuals. Anti-bacterial and anti-fungie properties of the laminate surface minimise the risk of spreading and transfering bacteria.",
            `<b>LOCKER SYSTEM</b>`,
            "Cuboid Locker system made with stylam compact laminates are ideal for new ultra modren, yet functional and durable locker systems.",
            "Available in varied colours, designs, sizes, formats and speciﬁcations, Cuboid locker system are perfect to your requirements , interiors and services. These systems are sturdy, durable, environment friendly and easy to use. They adapt well in high humidity zones like gymnasiums, spas, sauna centers, tennis clubs, swimming pools, commerical environments and centers.",
          ],
          features: [
            {
              title: "Anti-bacterial",
              image:
                "https://stylam-upload.s3.amazonaws.com/anti_bacterial_80ae52bf28.svg",
            },
            {
              title: "A Broad collection of designs",
              image:
                "https://stylam-upload.s3.amazonaws.com/wide_designs_59658779ac.svg",
            },
            {
              title: "Resistant to natural weather",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2508_c30d98a30b.svg",
            },
            {
              title: "Graﬃti resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2509_bd2f0115a8.svg",
            },
            {
              title: "Fire Retardant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2410_9a493d35c3.svg",
            },
            {
              title: "Eco sustainable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2463_c422c620bd.svg",
            },
            {
              title: "Resistant to harmful germs & cleaning agents",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2392_038619206e.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2513_2x_9b42034012.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2514_2x_c936af1fe4.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2515_2x_b2c2a94c18.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2517_2x_7a7bfe8a08.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2516_2x_482d0df284.png",
          ],
          sizes: [
            "THICKNESS : 12mm & above",
            "SIZE : 1860x4320 mm | 1860x3660mm",
          ],
        },
      },
      {
        title: "Exterior Cladding – Fascia",
        url: "exterior-cladding-fascia",
        image: "CompactLaminates3",
        data: {
          cover: "CompactCover4",
          info:
            "Industrial laminates are structural composite materials that are produced by impregnated ﬁbrous webs of material with thermosetting resin, then fusing multiple layers together under high temperature and pressure. These panels are available in the options of all brown, black and sandwiched design core.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2533_c5c39f7f05.png",
          title: "FASCIA - EXTERIOR CLADDING",
          descriptionImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2518_2x_ba58d92b95.png",
          info: [
            `<b>Stylam presents Fascia - an exterior cladding solution.</b>`,
            "Fascia, a lightweight and durable exterior facade material, oﬀers innovative solution to all construction needs. These HPL compact laminate comes with an intrinsic surface manufactured using conventional PMMA based UV ﬁlm or our USP KLEIBERIT Hot coating technology.",
            "The blend of cellulose ﬁbres and thermosetting resins, manufactured under high pressure and temperature yields a highly stable, dense panel with perfect strength to weight ratio. Available in wide range of colors, fascia is a perfect outdoor solution used in combination with other materials.",
            "Conventionally, these exterior panels are engineered using PMMA based UV technology and blocks more than 99.8% of UV light to boosts UV protection performance.",
            "STYLAM oﬀers Fascia with its revolutionary Hot coating technology from KLEIBERIT, Germany. Hot Coating is a fully automated process, where coating of high quality PU + acrylic is carried out on decorative surface to achieve extremely durable, high scratch and abrasion wear resistance as compared to conventional process.",
          ],
          features: [
            {
              title: "High UV Stability",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2519_a8126fffaa.svg",
            },
            {
              title: "Solid and Sturdy",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2520_b3c01de871.svg",
            },
            {
              title: "Wear Resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Moisture_Resistant_a9fae78225.png",
            },
            {
              title: "Scratch Resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Stain_resistant_cef867656c.svg",
            },
            {
              title: "Good Chemical Resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2523_8adcd6f7b0.svg",
            },
            {
              title: "Weather Resistance & Colour Stability",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2526_48c87613c5.svg",
            },
            {
              title: "Low Maintenance & Easy to Clean",
              image:
                "https://stylam-upload.s3.amazonaws.com/easy_maintainance_a3188e627b.svg",
            },
            {
              title: "Fire Retardant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2410_9a493d35c3.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2527_2x_eaee166424.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2528_2x_bde8ea52ce.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2529_2x_a8664cb466.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2531_2x_051b5ab477.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2530_2x_5424b5e96a.png",
          ],
          sizes: [
            "THICKNESS : 6mm & above",
            "SIZE : 1220x2440 mm | 1300x2800 mm | 1300x3050 mm",
          ],
        },
      },
      {
        title: "Industrial Laminates",
        url: "industrial-laminates",
        image: "CompactLaminates4",
        data: {
          cover: "CompactCover3",
          info:
            "Stylam Compact laminate combines the design varieties and strengthens HPL with design options of a through-dyed core. They come in varieties of thickness from 1 mm to 25mm with 12mm &18mm as standard for the most common applications.",
        },
        details: {
          coverImage:
            "https://stylam-upload.s3.amazonaws.com/Group_2534_2x_ae6588342d.png",
          title: "INDUSTRIAL LAMINATES",
          info: [
            "Industrial Laminates are produced by impregnated ﬁbrous webs of material with thermosetting resins,fusing multiple layers together under high temperature and pressure.",
            "Stable geometry in high temperature and minimal deformation when subjected to load makes Industrial laminate favorable to large number of Industries. Industrial laminates are long lasting and self sustainable solution for high impact surfaces such as cabin partitions, laboratory furniture, interior wall cladding, medical equipments including x-ray tables, wall panels & exhibition stands.",
            "These are available in options of all brown, black and sandwiched core design.",
          ],
          features: [
            {
              title: "Easily machinable",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2548_07631fcb54.svg",
            },
            {
              title: "Impact resistance",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2549_2de697b91a.svg",
            },
            {
              title: "Heat and frost resistant",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2550_4b455835ed.svg",
            },
            {
              title: "Resistant cleaning agents",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2551_efe491352d.svg",
            },
            {
              title: "High ﬂexural strength",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2552_0616e5e734.svg",
            },
            {
              title: "High tensile strength",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2487_f16073d1d5.svg",
            },
            {
              title: "Solid and Sturdy",
              image:
                "https://stylam-upload.s3.amazonaws.com/Group_2520_b3c01de871.svg",
            },
          ],
          images: [
            "https://stylam-upload.s3.amazonaws.com/Group_2555_2x_61059d4a4f.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2556_2x_b3657a589b.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2557_2x_ade35fc510.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2559_2x_d952baee25.png",
            "https://stylam-upload.s3.amazonaws.com/Group_2558_2x_a17bf47f84.png",
          ],
          sizes: [
            "THICKNESS : 2mm & above",
            "SIZE : 1860x3660 mm and 1860x4320mm",
          ],
        },
      },
    ],
  },
]

module.exports = { newCategories }
