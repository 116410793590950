import Helmet from "react-helmet"
import React from "react"

export default function HelmetProvider(props) {
  return (
    <Helmet
      title={props.title}
      link={[
        {
          rel: "stylesheet",
          href: "https://unpkg.com/tachyons@4/css/tachyons.min.css",
        },
        {
          rel: "stylesheet",
          href:
            "https://cdn.jsdelivr.net/npm/uikit@3.6.17/dist/css/uikit.min.css",
        },
        {
          rel: "stylesheet",
          href: "/assets/css/kurasa.min.css",
        },
      ]}
      script={[
        {
          src: "https://cdn.jsdelivr.net/npm/uikit@3.6.17/dist/js/uikit.min.js",
        },
        {
          src:
            "https://cdn.jsdelivr.net/npm/uikit@3.6.17/dist/js/uikit-icons.min.js",
        },
        { src: "https://kit.fontawesome.com/fcbb23f4cc.js" },
      ]}
    />
  )
}

{
  /* <script src="https://kit.fontawesome.com/fcbb23f4cc.js" crossorigin="anonymous"></script> */
}
