import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.png"
// import FacebookRoundedIcon from "@material-ui/icons/FacebookRounded"
// import LinkedInIcon from "@material-ui/icons/LinkedIn"
// import YouTubeIcon from "@material-ui/icons/YouTube"
// import InstagramIcon from "@material-ui/icons/Instagram"
// import TwitterIcon from "@material-ui/icons/Twitter"

export default function Footer() {
  const { categories } = require("../data.json")

  return (
    <div
      className="uk-section uk-section-muted"
      style={{ backgroundColor: "rgb(217, 217, 217)" }}
    >
      <div className="uk-container">
        <div className="uk-grid uk-grid-large" data-uk-grid>
          <div>
            <img src={logo} alt="" />
          </div>
          <div>
            <div>
              <ul class="uk-nav uk-nav-default">
                <li class="uk-active">
                  <a href="#">Information</a>
                </li>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about/who-are-we">About Us</Link>
                </li>
                <li>
                  <Link to="/products">Products</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/insights/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/health-and-environment">Health & Environment</Link>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div>
              <ul class="uk-nav uk-nav-default">
                <li class="uk-active">
                  <a href="#">Featured Products</a>
                </li>
                {categories.map(cat => (
                  <li className="capitalize">
                    <Link to={"/products/" + cat.url}>{cat.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div>
            <div>
              <ul class="uk-nav uk-nav-default">
                <li class="uk-active">
                  <a href="#">Links</a>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/insights/news/1">Media</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="http://14.99.245.252:8081/HRMS/login.php">
                    Login
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div>
              <ul class="uk-nav uk-nav-default">
                <li class="uk-active">
                  <a href="#">Address 1</a>
                </li>
                <li>
                  <Link to="#">
                    Plot no 192-193 <br />
                    industrial Area Phase 1, <br />
                    Haryana, 134113, India
                  </Link>
                </li>
                <li className="uk-active">
                  <Link to="#">Email</Link>
                </li>
                <li>
                  <a href="mailto:info@stylam.com">info@stylam.com</a>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div>
              <ul class="uk-nav uk-nav-default">
                <li class="uk-active">
                  <a href="#">Address 2</a>
                </li>
                <li>
                  <Link to="#">
                    Vill. Manak Tabra towards Raipur Rani, <br />
                    Mattewala Chowk, Distt. Panchkula, <br />
                    Haryana, India
                  </Link>
                </li>
              </ul>
              <div class="uk-margin-large-top f-icons">
                <a
                  target="_blank"
                  href="https://www.facebook.com/stylamlaminate/"
                  class="  mr3 a-dec"
                  // style={{ color: "black" }}
                  // uk-icon="facebook"
                >
                  <i class="fab fa-facebook fa-2x icon-color"></i>
                </a>

                <a
                  target="_blank"
                  href="https://twitter.com/stylamlaminate"
                  class=" mr3 a-dec"
                  // style={{ color: "black" }}
                  // uk-icon="twitter"
                >
                  <i class="fab fa-twitter fa-2x icon-color"></i>
                </a>

                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UC6hgDnQQA-gRCjksirfyWxw"
                  class=" mr3 a-dec"
                  // style={{ color: "black" }}
                  // uk-icon="youtube"
                >
                  <i class="fab fa-youtube fa-2x icon-color"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/stylamlaminates/"
                  class="mr3 a-dec"
                  // style={{ color: "black" }}
                  // uk-icon="instagram"
                >
                  <i class="fab fa-instagram fa-2x icon-color"></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/stylamindustries/"
                  class=" uk-margin-small-right a-dec"
                  // uk-icon="linkedin"
                >
                  <i
                    class="fab fa-linkedin-in fa-2x icon-color"
                    // style={{ color: "black" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}
