import React, { useEffect } from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import HelmetProvider from "../components/helmet-provider"
import { navigate } from "gatsby"

// if (typeof window != "undefined") {
//   window.redirected = false
//   window.redirectTo = window.location.pathname
// }

export default function StandardLayout(props) {
  // if (typeof window != "undefined" && !window.redirected) {
  //   navigate("/redirected")
  // }

  return (
    <div>
      <HelmetProvider title={props.title} />
      <Header></Header>
      {props.children}
      <Footer></Footer>
    </div>
  )
}
